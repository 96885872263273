var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: { width: "160", trigger: "hover", placement: "left" },
      on: { show: _vm.onPopShow, "after-leave": _vm.onPopHide },
      scopedSlots: _vm._u([
        {
          key: "reference",
          fn: function() {
            return [
              _c(
                "el-button",
                { attrs: { type: "primary", size: "mini", plain: "" } },
                [_vm._v("充值进度")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.progressData
        ? _c(
            "el-steps",
            {
              staticClass: "phone-charge-step",
              attrs: { direction: "vertical", active: 99 }
            },
            [
              _vm._l(_vm.progressData, function(item, index) {
                return [
                  item.is_main
                    ? _c("el-step", {
                        key: index,
                        attrs: { title: "当前状态", icon: "el-icon-s-help" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "description",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "phone-charge-step-text" },
                                    [
                                      _c("span", [_vm._v("订单状态：")]),
                                      _c(
                                        "el-tag",
                                        {
                                          staticStyle: {
                                            display: "inline-block"
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: item.statusTag
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.statusText || item.status
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  item.remark
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "phone-charge-step-text"
                                        },
                                        [_vm._v(_vm._s(item.remark))]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "p",
                                    { staticClass: "phone-charge-step-text" },
                                    [_vm._v(_vm._s(item.created_at))]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : _c("el-step", {
                        key: index,
                        attrs: { title: "渠道分配" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "description",
                              fn: function() {
                                return [
                                  _c(
                                    "p",
                                    { staticClass: "phone-charge-step-text" },
                                    [
                                      _vm._v(
                                        "skuid：" +
                                          _vm._s(item.sku_id) +
                                          "-" +
                                          _vm._s(item.channelName)
                                      )
                                    ]
                                  ),
                                  item.remark
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "phone-charge-step-text"
                                        },
                                        [_vm._v(_vm._s(item.remark))]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "p",
                                    { staticClass: "phone-charge-step-text" },
                                    [_vm._v(_vm._s(item.created_at))]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                ]
              })
            ],
            2
          )
        : _c(
            "div",
            { staticStyle: { padding: "40px 0", "text-align": "center" } },
            [
              _c("i", {
                staticClass: "el-icon-loading",
                staticStyle: {
                  margin: "auto",
                  "font-size": "24px",
                  color: "#ccc"
                }
              })
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }